@import '../../../../default';

.pd-add-quiz-container {
    max-width: 800px;
    margin: auto;

    .ps-quiz-question-form-container {
        display: flex;
    
        .ps-quiz-question-form-collapser {
            width: 100%;
        }

        .ps-question-delete-button {
            color: $primary-color;
            padding: 1rem;
            display: block;
        }
    }
}