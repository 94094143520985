@import '../../../default';

.ps-admin-page {
    display: flex;
    align-items: stretch;
    height: auto;
    min-height: 100%;
    background: #f1f2f3;

    .ps-admin-layout {
        padding: 1rem;
        flex: 1;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;

        .ps-admin-content {
            background: #ffffff;
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
            padding: 0 1rem;
            margin-bottom: 1rem;
            height: auto;

            header {
                font-weight: bold;
                font-size: 1rem;
                background: transparent;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    
        .ps-admin-divider {
            border-width: 1px;
            border-color: rgba(0, 0, 0, 0.05);
            margin-bottom: 1rem;
            width: 100%;
        }
    
        .ps-admin-option {
            margin-bottom: 1rem;

            .ant-card-body {
                min-height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &::after, &::before {
                    content: unset;
                }
            }

            .ps-admin-list-item {
                .ant-list-item-extra {
                    margin-left: 16px!important;
                }
            }
    
            .ps-admin-option-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .ps-admin-option-icon {
                color: $primary-color;
                padding-right: 0.5rem;
            }

            .ps-admin-option-footer {
                display: flex;
                width: 100%;
                margin-top: 2rem;
                justify-content: space-between;
                align-items: center;

                & > span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 10px;
                }
            }
        }
    
        .ps-admin-header {
            background: transparent !important;
            padding-left: 0;

            .ps-admin-header-title {
                color: $primary-color;
            }
        }
    }

    .ps-admin-footer {
        text-align: center;
        background: transparent;
    }
}