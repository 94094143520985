@import '../../../default';

.ps-main-menu-wrapper {
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .05);

    .ps-main-menu-brand {
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;

        img {
            display: block;
            margin: auto;
            transition: transform .3s ease-out;
        }
    }
}